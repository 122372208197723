/* urbanist-regular - latin */
@font-face 
  font-family: 'Urbanist'
  font-style: normal
  font-weight: 400
  src: url('../assets/fonts/urbanist-v7-latin-regular.eot')
  src: local(''),url('../assets/fonts/urbanist-v7-latin-regular.eot?#iefix') format('embedded-opentype'),url('../assets/fonts/urbanist-v7-latin-regular.woff2') format('woff2'), url('../assets/fonts/urbanist-v7-latin-regular.woff') format('woff'), url('../assets/fonts/urbanist-v7-latin-regular.ttf') format('truetype'),url('../assets/fonts/urbanist-v7-latin-regular.svg#Urbanist') format('svg') 

/* urbanist-900 - latin */
@font-face 
  font-family: 'Urbanist'
  font-style: normal
  font-weight: 900
  src: url('../assets/fonts/urbanist-v7-latin-900.eot') /* IE9 Compat Modes */
  src: local(''),url('../assets/fonts/urbanist-v7-latin-900.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/urbanist-v7-latin-900.woff2') format('woff2'),url('../assets/fonts/urbanist-v7-latin-900.woff') format('woff'),  url('../assets/fonts/urbanist-v7-latin-900.ttf') format('truetype'), url('../assets/fonts/urbanist-v7-latin-900.svg#Urbanist') format('svg')
