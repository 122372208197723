/* urbanist-regular - latin */
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/urbanist-v7-latin-regular.eot");
  src: local(""), url("../assets/fonts/urbanist-v7-latin-regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/urbanist-v7-latin-regular.woff2") format("woff2"), url("../assets/fonts/urbanist-v7-latin-regular.woff") format("woff"), url("../assets/fonts/urbanist-v7-latin-regular.ttf") format("truetype"), url("../assets/fonts/urbanist-v7-latin-regular.svg#Urbanist") format("svg");
}
/* urbanist-900 - latin */
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/urbanist-v7-latin-900.eot");
  src: local(""), url("../assets/fonts/urbanist-v7-latin-900.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/urbanist-v7-latin-900.woff2") format("woff2"), url("../assets/fonts/urbanist-v7-latin-900.woff") format("woff"), url("../assets/fonts/urbanist-v7-latin-900.ttf") format("truetype"), url("../assets/fonts/urbanist-v7-latin-900.svg#Urbanist") format("svg");
}
* {
  font-family: "Urbanist", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: #fff;
}

a {
  color: #fff;
}

.slide-show-bg {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/img/background.jpg);
  z-index: 2;
  position: relative;
}
.slide-show-bg::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.info-div-wrapper {
  background: #000;
  border-radius: 5px;
  padding: 40px;
  max-width: 480px;
  width: 100%;
  box-shadow: 0 0 50px rgba(255, 255, 255, 0.2);
  display: block;
  position: relative;
}
.info-div-wrapper > img, .info-div-wrapper > a.logo {
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 40px;
  display: block;
}
.info-div-wrapper .logo-img {
  max-width: 100%;
}
.info-div-wrapper > p {
  max-width: 280px;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.info-div-wrapper > hr {
  border-color: rgba(255, 255, 255, 0.2);
  margin-bottom: 40px;
}

.rights-nav {
  width: 100%;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
}
.rights-nav > ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.rights-nav > ul > li a {
  color: #fff;
  padding: 0 30px;
  text-decoration: none;
}

.to-54-btn {
  width: 100%;
  background: #001ee6;
  color: #fff;
  padding: 15px;
  display: block;
  text-align: center;
  text-decoration: none;
  margin-top: 30px;
  border-radius: 4px;
  font-weight: 900;
  border: 4px solid #001ee6;
  transition: ease all 400ms;
  /*Impressum*/
}
.to-54-btn:hover {
  background: transparent;
}

.info-div-wrapper.impressum {
  max-width: 850px;
  max-height: 80vh;
  overflow: auto;
}
.info-div-wrapper.impressum .info-div {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
  padding-bottom: 15px;
}