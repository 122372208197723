@import './var', './fonts'
// Add other Stylesheets like this: ,'./buttons', './slider'

// Run "npm run dev" to start
// Open Dev Sources in Chrome - Page Sources -> Overrides -> Enable, write something into it
// Ready to go ;)

*
    font-family: $regular
    box-sizing: border-box


body 
    margin: 0
    padding: 0
    overflow: hidden
    color: #fff

a 
    color: #fff


.slide-show-bg
    width: 100%
    height: 100vh
    display: flex
    align-items: center
    justify-content: center
    
    
    background-image: url(../assets/img/background.jpg)
    z-index: 2
    position: relative
    &::after
        content: ""
        width: 100%
        height: 100%
        position: absolute
        left: 0
        top: 0
        backdrop-filter: blur(5px)
        background: rgba(0,0,0,.5)
        z-index: -1


.info-div-wrapper
    background: #000
    border-radius: 5px
    padding: 40px
    max-width: 480px
    width: 100%
    box-shadow: 0 0 50px rgba(255,255,255,.2)

    display: block
    position: relative
    > img, > a.logo 
        max-width: 300px
        margin: 0 auto
        margin-bottom: 40px
        display: block
    .logo-img
        max-width: 100%

    > p 
        max-width: 280px
        text-align: center
        display: block
        margin: 0 auto

    > hr 
        border-color: rgba(255,255,255,.2)
        margin-bottom: 40px

.rights-nav 
    width: 100%
    //background: rgba(0,0,0,.2)
    color: #fff
    position: absolute
    bottom: 0
    left: 0
    padding: 15px

    > ul 
        display: flex
        align-items: center
        justify-content: center
        list-style: none
        margin: 0 
        padding: 0
        > li a 
            color: #fff
            padding: 0 30px
            text-decoration: none

.to-54-btn
    width: 100%
    background: $prime
    color: #fff
    padding: 15px
    display: block
    text-align: center
    text-decoration: none
    margin-top: 30px
    border-radius: 4px
    font-weight: 900
    border: 4px solid $prime
    transition: ease all 400ms
    &:hover
        background: transparent 





    /*Impressum*/

.info-div-wrapper.impressum
    max-width: 850px
    max-height: 80vh
    overflow: auto

    .info-div
        border-bottom: 1px solid rgba(255,255,255,.1)
        margin-bottom: 15px
        padding-bottom: 15px